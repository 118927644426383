<template>
  <div id="users-audits">
    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <h5>Nombre Usuario</h5>
            <b-row>
              <b-col xl="3" sm="12">
                <b-form-input id="name"
                  v-model="nameUser"
                  name="name"
                  disabled />
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mt-2">
        <b-card no-body class="mb-0">
          <users-audits-list :audits="audits"></users-audits-list>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import UsersAuditsList from './components/UsersAuditsList'

  export default{
    name: 'UsersAuditsView',
    components: {UsersAuditsList},
    props: ['audits'],
    data() {
      return {}
    },
    computed: {
      nameUser () {
        return this.audits ? `${this.audits.name} ${this.audits.last_name}` : ''
      },
    },
  }
</script>